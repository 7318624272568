/* Navbar */

.navbar {
  background-color: #282c2f !important;
  color: white;
  padding: 0rem 1rem;
  position: absolute;
  width: 100%;
  z-index: 999;
}
.brandLogo {
  width: 50px;
}
ul {
  list-style-type: none;
  margin-top: 0.5rem;
}
.mainNavOptions {
  li {
    display: inline-block;
  }
  a {
    margin-right: 1.5rem;
    text-decoration: none;
    color: #fff;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
  a:hover {
    border-bottom: 1px solid #fff;
  }
}

// Toggle Btn
.toggleBtn {
  border: none;
}
.togglerLineOne,
.togglerLineTwo,
.togglerLineThree {
  height: 5px;
  transition: all 1s;
  background-color: #fff;
  margin-bottom: 0.3rem;
}
.togglerLineOne {
  width: 25px;
}
.togglerLineTwo {
  width: 35px;
}
.togglerLineThree {
  width: 50px;
}
.toggleBtn:hover {
  .togglerLineOne {
    width: 50px;
  }
  .togglerLineTwo {
    width: 50px;
  }
}

@media (max-width: 980px) {
  ul {
    margin-top: 0;
  }
  .mainNavOptions {
    ul {
      display: block;
      margin-bottom: 0 !important;
      margin-top: 0.5rem;
      padding: none !important;
    }
    li {
      display: block;
      margin-bottom: 0.9rem;
    }
    a {
      padding-bottom: 0.5rem;
      cursor: pointer;
    }
    a:hover {
      border-bottom: 1px solid #fff;
    }
  }
  .brandLogo {
    width: 35px;
  }

  .toggleBtn {
    .togglerLineOne,
    .togglerLineTwo,
    .togglerLineThree {
      height: 3px;
    }
    .togglerLineOne {
      width: 20px;
    }
    .togglerLineTwo {
      width: 25px;
    }
    .togglerLineThree {
      width: 35px;
      margin-bottom: 0 !important;
    }
  }
  .toggleBtn:hover {
    .togglerLineOne {
      width: 35px;
    }
    .togglerLineTwo {
      width: 35px;
    }
  }
}
