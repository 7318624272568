// Get In Touch Form
.getInTouch {
  min-height: 100vh;
  display: grid;
  place-items: center;
  background-color: #f4f0ed;
}
.getInTouchWrapper {
  padding: 5rem 0 3rem 0;
  width: 90%;
  margin: auto;
  text-align: center;
}
.title {
  font-size: 2.5rem;
  font-family: cormorant garamond, serif;
  line-height: 1.3em;
  letter-spacing: 0.2em;
}
.subtitle {
  text-transform: none;
  font-style: italic;
  color: #111;
  font-family: cormorant garamond, serif;
  font-size: 1.5rem;
}
.form {
  //   display: flex;
  //   border: 1px solid red;
}
.formInput {
  margin: 2rem 0;
  width: 80%;
  padding: 0 2px;

  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: calc(26px - (0px - 2px));
  color: #84847c;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  //   border: 1px solid red;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
}

.formTextArea {
  padding: 0 2px;

  margin: 2rem 0;
  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  width: 90%;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: calc(26px - (0px - 2px));
  color: #111;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.formInput::placeholder,
.formTextArea::placeholder {
  font-weight: 400;
}

.formSubmitBtn {
  margin: 1rem;
  letter-spacing: 0.15rem;
  border: none;
  padding: 1rem 2.5rem;
  background-color: #222;
  transition: all 1s;
  border-radius: 10px;
  color: #fff;
}
.formSubmitBtn:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .formInput {
    width: 100%;
  }
  .formTextArea {
    width: 100%;
  }
  .title {
    font-size: 2rem;
  }
  .subtitle {
    font-size: 1.25rem;
  }
}
