// General
.titleHeading {
  font-family: 'Cormorant Garamond', sans-serif;
  color: #4d504a;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.titleParagraph {
  font-family: 'Mulish', sans-serif;
  font-size: 1.25rem;
  width: 80%;
}
img {
  width: 80%;
}

.imgDivTitle {
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
.imgDivSubTitle {
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 2rem;
}

// Top Section
.topSection {
  background-color: #f4f0ed;
  padding: 4rem 0 0 0;
}
.topSectionWrapper {
  width: 100%;
  height: 100%;
  margin: auto;
}

.topDivOne {
  position: relative;
  .imgDiv {
    position: absolute;
    top: 13rem;
  }
}
.topDivTwo {
  padding-bottom: 6rem;
}

// Bottom Section
.bottomSection {
  width: 100%;
  margin: auto;
  padding: 0 0 3rem 0;
}
.bottomSectionWrapper {
  //   margin-top: 2rem;
}
.bottomDivOne {
  margin-top: 14rem;
}
.bottomDivTwo {
  position: relative;
  .imgDiv {
    position: absolute;
    top: -2rem;
  }
}

@media (max-width: 768px) {
  .titleParagraph {
    margin-bottom: 2rem;
    width: 100%;
  }
  .topSectionWrapper,
  .bottomSection {
    width: 100%;
  }
  img {
    width: 100%;
  }
  .topDivOne {
    position: static;
    .imgDiv {
      position: static;
    }
  }
  .topDivTwo {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
  .bottomSectionWrapper {
    margin-top: 1rem;
  }
  .bottomDivOne {
    margin: 2rem 0;
  }
  .bottomDivTwo {
    position: static;
    .imgDiv {
      position: static;
    }
  }
}
