.picturePortfolio {
  padding: 1rem 0 0 0;
  min-height: 100vh;
  display: grid;
  place-items: center;
  text-align: center;
}
.title {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-family: cormorant garamond, serif;
  line-height: 1.3em;
  letter-spacing: 0.2em;
}
.picture {
  //   border: 2px solid red;
  transition: all 1s;
  padding: 0 !important;
  img {
    width: 100%;
    min-height: 120px;
    height: 100%;
  }
}
.picture:hover {
  transform: scale(1.03);
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
  .subtitle {
    font-size: 1.25rem;
  }
}
