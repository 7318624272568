.footer {
  background-color: #282c2f;
  color: white;
}
.footerWrapper {
  width: 90%;
  margin: auto;
  padding: 1rem 0;
}
.footerDivOne {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.iconDiv {
  display: flex;
}
.icon {
  font-size: 1.2rem;
  margin-right: 1.5rem;
  margin: 0.5rem !important;
}
.icon:hover {
  color: red;
}
.iconTxt {
  font-size: 1.2rem;
  // border: 1px solid red;
  display: block;
  padding: 0 !important;
  margin: 0.5rem !important;
  align-self: center;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.075rem;
  font-weight: 300;
}

// Footer Div Two
.footerDivTwo {
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.aboutTitle {
  font-weight: 400;
  letter-spacing: 0.075rem;
  font-size: 1.5rem;
}
.aboutParagraph {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.075rem;
  margin-bottom: 0.5rem;
}

.socialIcons {
  display: flex;
  justify-content: center;
  transition: all 1s;
  h4 {
    margin-right: 1.5rem;
    transition: all 0.6s;
    font-size: 1.5rem;
  }
}
.fbIcon:hover {
  color: #4267b2;
}
.igIcon:hover {
  color: #8a3ab9;
}
.ytIcon:hover {
  color: #ff0000;
}

@media (max-width: 600px) {
  .footerWrapper {
    width: 100%;
  }
  .footerDivOne {
    margin-bottom: 1rem;
  }
}
