// Pricing Carts
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap");

// General
.pricing {
  // text-align: center;
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.pricingWrapper {
  padding: 5rem 0;

  width: 100%;
}
.title {
  font-size: 2.5rem;
  font-family: cormorant garamond, serif;
  line-height: 1.3em;
  text-align: center;
  letter-spacing: 0.2em;
}
.subtitle {
  text-transform: none;
  font-style: italic;
  text-align: center;
  color: #84847c;
  font-family: cormorant garamond, serif;
  font-size: 1.6rem;
  margin-bottom: 4rem;
}
.pricingDetails {
  display: grid;
  place-items: center;
  // border: 1px solid orangered;
  // text-align: center;
}
.pricingPlans {
  width: 100%;
}
.pricingPlan {
  margin-bottom: 2rem;

  h2 {
    font-family: cormorant garamond, serif;
  }
  p {
    font-family: Muli, sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    font-weight: 300;
    line-height: 1.6em;
  }
}
.pricingContainer {
  // width: 90%;
  // border: 1px solid limegreen;
  // display: flex;
  // justify-content: center;
  margin-bottom: 1.5rem;
}
.pricingTxt {
  font-size: 2.5rem;
  // border: 1px dotted blue;
  font-family: "Merriweather", serif;
  align-self: center;
}
.pkrLogo {
  width: 3rem !important;
  margin-right: 1.5rem;
  // border: 1px dotted orange;
}
.checkIcon {
  font-size: 1.5rem;
  color: rgb(150, 221, 44);
}
.crossIcon {
  color: red;
  font-size: 1.5rem;
}

.feautures {
  margin-bottom: 1rem;
  border: 1px solid black;
}
.feautureRow {
  margin: auto;
  font-weight: 300;
  .iconDiv {
    margin-right: 0.5rem;
  }
  .iconTxt {
    // flex: 1;
    font-size: 1.25rem;
    // border: 1px solid purple;
    // text-align: left;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
  .subtitle {
    font-size: 1.25rem;
  }
  .pricingTxt {
    font-size: 1.5rem;
  }
  .pkrLogo {
    width: 2.5rem !important;
    margin-right: 0.5rem;
  }
  .feautureRow {
    width: 90%;
    .iconTxt {
      p {
        font-size: 1.25rem;
      }
    }
  }
}
