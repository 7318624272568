/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Dancing+Script:wght@500&family=Lato:wght@100;300;400&family=Mulish:wght@200&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Lato', sans-serif;
}
.container {
  max-width: '100%' !important;
}

/* Loader */
.loader {
  position: absolute;
  top: calc(50% - 3em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pricing Carts */

.Basic {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 1s;
}
.Basic .pricingPlanTitle {
  background-color: #f72585;
  color: white;
  padding: 1rem 0;
  text-align: center;
  margin-bottom: 1rem;
}
.Basic .pricingDetails {
  padding: 2rem;
}
.Basic:hover {
  box-shadow: 20px 30px 42px rgba(0, 0, 0, 0.25),
    0 30px 30px rgba(0, 0, 0, 0.25);
}

.Standard {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: 1s all;
}
.Standard .pricingPlanTitle {
  background-color: #4cc9f0;
  color: white;
  padding: 1rem 0;
  margin-bottom: 1rem;
  text-align: center;
}
.Standard .pricingDetails {
  padding: 2rem;
}
.Standard:hover {
  box-shadow: 20px 30px 42px rgba(0, 0, 0, 0.25),
    0 30px 30px rgba(0, 0, 0, 0.25);
}

.Premium {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transition: all 1s;
}
.Premium .pricingPlanTitle {
  background-color: #3a0ca3;
  color: white;
  padding: 1rem 0;
  text-align: center;
  margin-bottom: 1rem;
}
.Premium .pricingDetails {
  padding: 2rem 2rem 3rem 2rem;
}
.Premium:hover {
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.3), 0 30px 35px rgba(0, 0, 0, 0.25);
}
